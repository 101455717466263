import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    @Inject(DOCUMENT) public readonly document: Document,
    public readonly auth: Auth0Service
  ) {}

  public logout(): void {
    this.auth.logout({
      logoutParams: {
        returnTo: this.document.location.origin,
      },
    });
  }

  public checkTokenExpiry(): void {
    this.auth.idTokenClaims$.subscribe(claims => {
      const exp = claims?.exp;
      if (exp) {
        const expiryDate = new Date(exp * 1000);
        const now = new Date();
        if (expiryDate < now) {
          this.logout();
        }
      }
    });
  }
}
